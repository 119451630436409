.buttons {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid black;
    padding: 10px;
    background-color: rgb(206, 206, 206);
    width: 250px;
}

.buttons > * {
    margin: 5px 0;
}

.search-results {
    max-height: 500px;
    overflow-y: auto;
}

.search-result {
    background-color: rgb(177, 177, 177);
    border-radius: 5px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px 0;
}

.buttons button {
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    background-color: white;
}

.search-info {
    margin-right: 5px;
    flex-grow: 1;
    min-width: 0;
}

.search-name {
    margin-bottom: 5px;
    word-wrap: break-word;
}

.search-sci-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.search-sci-name {
    font-style: italic;
    margin-right: 5px;
    min-width: 0;
    word-wrap: break-word;
}

/* Tooltip container */
.tooltip {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 1px solid black;
    border-radius: 100%;
    color: blue;
    font-weight: 800;
}
  
/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    border: 2px solid black;
    background-color: white;
    color: black;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    min-width: 100px;
    font-weight: 100;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    left: -3px;
    top: -3px;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

.top-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.home-button {
    cursor: pointer;
}

.search-clear {
    cursor: pointer;
    margin-left: 5px;
}

.zoom-buttons {
    position: fixed;
    right: 0;
}

.zoom-button {
    width: 35px;
    height: 35px;
    cursor: pointer;
    text-align: center;
    background-color: white;
    font-size: 20pt;
    border: 1px solid black;
    border-radius: 5px;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}