#chart {
    background-color: rgb(248, 234, 208);
}

.node {
    box-sizing: border-box;
    padding: 10px;
    height: 100%;
    border: 1px solid black;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.node.highlighted {
    border: 5px solid #0089a8;
}

.node.toggled {
    border: 5px solid #76307f;
}

.node-name {
    font-size: 14pt;
    margin-bottom: 5px;
    color: #270;
}

.node-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.node-rank {
    text-align: center;
    flex-grow: 1;
}

.node-wiki {
    cursor: pointer;
    width: 25px;
    height: 25px;
    padding: 0;
    border-radius: 5px;
    font-family: 'Linux Libertine';
    font-size: 12pt;
    color: black;
    text-decoration: none;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #DDD;
}

.node-names {
    flex-grow: 1;
    font-size: 12pt;
    word-wrap: break-word;
    min-width: 0;
    margin: 5px 0;
}

.node-sci-name {
    font-style: italic;
}

.node-button-div {
    cursor: pointer;
}

.node-arrow {
    position: absolute;
    cursor: pointer;
    left: 87px;
    background-color: rgba(255, 255, 255, 0.5);
    border: 1px solid #666;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    border-radius: 2px;
}
 
.node-arrow.up {
    top: -22px;
}
 
.node-arrow.down {
    bottom: -38px;
}